import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { Layout } from '../components/Layout/Layout'
import { SEO } from '../helpers/SEO'
import { truncate } from '../helpers/truncate'
import { Listing } from '../components/Listing'
import { HeroImage } from '../components/HeroImage'
import { GatsbyFluidImageProps } from 'gatsby-source-sanity'
import { InLink } from '../components/atoms/ui/links'
import { layout } from '../components/GlobalStyles/vars/layout'
import { Author } from '../components/blog/blogTypes'

const BlogPosts = styled.ul`
  ${layout.pageWidth}
`

const LinkContainer = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-top: 30px;
`

interface Data {
  data: {
    allSanityPost: {
      nodes: Array<Post>
    }
    file: {
      childImageSharp: {
        fluid: GatsbyFluidImageProps
      }
    }
  }
  pageContext: {
    currentPage: number
    numPages: number
  }
}

interface Post {
  title: string
  author: Author
  _rawDescription: React.ReactNode
  mainImage: {
    asset: {
      fixed: {
        src: string
      }
    }
    alt: string
  }
  slug: {
    current: string
  }
  publishedAt: string
  id: string
}

const Blog = ({ data, pageContext }: Data): JSX.Element => {
  const posts = data.allSanityPost.nodes
  const { currentPage, numPages } = pageContext
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage = currentPage - 1 === 1 ? '/blog' : '/blog/' + (currentPage - 1).toString()
  const nextPage = '/blog/' + (currentPage + 1).toString()

  return (
    <>
      <SEO
        title="Blog Posts"
        description="Read more about flying, learning to fly, and what we are up to at Queen City Flying Service."
      />
      <Layout>
        <HeroImage fluid={data.file.childImageSharp.fluid} alt="replace" />
        <h1>Blog Post Archive</h1>
        <BlogPosts>
          {posts.map(
            (post: Post): JSX.Element => {
              const img = post.mainImage

              return (
                <Listing
                  title={truncate(post.title, 100)}
                  key={post.id}
                  img={img?.asset.fixed.src}
                  alt={img?.alt}
                  subtitle={`${post.publishedAt} | ${post.author.name}`}
                  link={`/blog/${post.publishedAt}/${post.slug.current}/`}
                  desc={post._rawDescription}
                />
              )
            },
          )}
        </BlogPosts>
        <LinkContainer>
          {!isFirst && <InLink to={prevPage}>...Back</InLink>}
          {!isFirst && !isLast && <span> | </span>}
          {!isLast && <InLink to={nextPage}>More...</InLink>}
        </LinkContainer>
      </Layout>
    </>
  )
}

export default Blog

export const query = graphql`
  query blogQuery($skip: Int!, $limit: Int!) {
    allSanityPost(limit: $limit, skip: $skip, sort: { fields: publishedAt, order: DESC }) {
      nodes {
        title
        author {
          name
        }
        _rawDescription
        mainImage {
          asset {
            fixed(width: 100, height: 100) {
              src
            }
          }
          alt
        }
        slug {
          current
        }
        publishedAt(formatString: "MM-YYYY")
        id
      }
    }
    file(relativePath: { eq: "HY_1107.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
