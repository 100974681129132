import React from 'react'
import styled from 'styled-components'
import { InLink } from './atoms/ui/links'
import { BlockContent } from './BlockContent'
import { colors } from './GlobalStyles/vars/colors'
import { MdBook } from 'react-icons/md'
import { device } from './GlobalStyles/helpers/device'

const Container = styled.li`
  display: flex;
  align-items: stretch;
  flex-direction: column;

  + li {
    margin-top: 40px;
  }

  @media ${device.mobileL} {
    flex-direction: row;
  }
`

const Meta = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-size: 0.8em;
    margin-top: 5px;
  }

  p {
    margin-top: 8px;
    padding: 0;
  }
`

const Image = styled.div`
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${colors.grey};
  font-size: 0.8em;
  width: 100px;
  height: 100px;
`

interface Props {
  className?: string
  title: string
  desc?: React.ReactNode
  img?: string
  alt?: string
  link?: string
  key?: string
  subtitle?: string
}

const ImageError = () => {
  return (
    <>
      <MdBook />
      <br />
      <span>No Image</span>
    </>
  )
}

export const Listing = ({ link, title, desc, img, alt, className, key, subtitle }: Props): JSX.Element => {
  return (
    <Container key={key} className={className}>
      <Image>{img ? <img src={img} alt={alt} /> : <ImageError />}</Image>
      <Meta>
        <InLink to={link || '/blog'}>{title}</InLink>
        <span>{subtitle}</span>
        {desc && <BlockContent body={desc} />}
      </Meta>
    </Container>
  )
}
