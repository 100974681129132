import React from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { GatsbyFluidImageProps } from 'gatsby-source-sanity'

const style = `
    height: 320px;
    width: 100%;
    vertical-align: top;
    object-fit: cover;
  `

const Container = styled(Img).attrs((props: Props) => {
  props.fluid
})`
  ${style}
`
const SrcContainer = styled.img`
  ${style}
`

interface Props {
  alt: string
  fluid?: GatsbyFluidImageProps
  src?: string
  className?: string
}

export const HeroImage = ({ alt, fluid, src, className }: Props): JSX.Element => {
  if (fluid) {
    return <Container alt={alt} fluid={fluid} className={className} />
  } else if (src) {
    return <SrcContainer alt={alt} src={src} className={className} />
  } else {
    return <p>Failed to Load Image</p>
  }
}
